/** @jsx jsx */
import { jsx, css } from "theme-ui"
import React from "react"
import { Wrapper, Toggle, SwitchLabel } from '../styled/switch.styled';

// Adapted from this pen by @sashatran on codepen: https://codepen.io/sashatran/pen/vzRjZr

const Switch = ({ checked, onChange, ...props }) => {
  const mode = checked ? 'dark' : 'light';
  return (
  <Wrapper {...props}>
    <input sx={{visibility: 'hidden'}} type="checkbox" id="switch" onClick={onChange}/>
    <SwitchLabel
      sx={{ backgroundColor: `modes.${mode}.background`, borderColor: `modes.${mode}.highlight`}}
      htmlFor="switch" 
      darkMode={checked}
    >
      <Toggle
       sx={{ backgroundColor: `modes.${mode}.highlight`, color: `modes.${mode}.highlight` }}
       darkMode={checked} 
      />
    </SwitchLabel>
  </Wrapper>);
}

export default Switch
