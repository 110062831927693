import styled, { css } from 'styled-components';

export const Toggle = styled.div`
  width: 25px;
  height: ${(props) => props.darkMode ? '12.5px' : '25px' };
  border-radius: ${(props) => props.darkMode ? '25px 25px 0 0' : '25px'};
  position: absolute;
  left: 3px;
  transition: 0.6s all ease-in-out;
  ${(props) => toggleTheme(props.darkMode)}
  margin-top: ${(props) => props.darkMode ? '8px' : '2px'};
`;

const toggleTheme = (darkMode) => darkMode && css`
  transform: scale(0.9) translateX(75px) rotate(90deg);
  background: transparent;
  box-shadow: 0px -6px 0 0;
`;

export const SwitchLabel = styled.label`
  width: 100px;
  height: 30px;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  transition: 0.6s all ease-in-out;
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  padding: 5px;
	display: flex;
	align-items: center;
  justify-content: center;
  z-index: 10;
`;